export default {
  setMessages(state, payload) {
    state.messages = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setIsDialog(state, payload) {
    state.isDialog = payload;
  },
  setChatInstanceId(state, payload) {
    state.chatInstanceId = payload;
  },
};
