export default {
  greeting:
    "Hey!" +
    "<br> <br>Danke, dass du dich mit deinem Anliegen zum Thema Schwangerschaftsabbruch an mich wendest." +
    "<br><br> Hier ist eine kleine Vorauswahl an Themen, du kannst mir aber auch gerne direkt eine spezifische Frage stellen." +
    "<br><br>Diese Dienstleistung ist noch in der Pilotphase. Sie dient nicht dazu, eine Entscheidung für die Nutzer:innen zu treffen." +
    "Sie soll lediglich informieren und aufklären, um während des persönlichen und individuellen Entscheidungsprozesses zu unterstützen. Die Informationen sind generalisiert und allgemeingültig.",
  suggestions: [
    {
      text: "Allgemeine Informationen",
    },
    {
      text: "Beratungsstellen",
    },
    {
      text: "Abtreibungsmöglichkeiten",
    },
    {
      text: "Folgen und Risiken",
    },
  ],
  noAnswerText:
    "Leider kann ich Ihre Frage nicht beantworten. Bitte versuchen Sie es mit einer anderen Formulierung",
  noAnswerSuggestions: [],
  botName: "YourChoice",
  botTitle: "",
  client: "https://analytics-api.institut.digital",

  endpoint: "knowledgebases/f4278eca-42a3-44b3-bd84-7984be61c6cf/generateAnswer",
  endpointAuthKey: "955cd5f4-211c-4eee-832e-061983caf941",

  // confidence threshold in percent
  confidenceScore: 0,

  primaryColor: "#5d5d69",
  secondaryColor: "",

  // e.g https://analytics-api.institut.digital/api/ext/XYZ
  analyticsEndpoint: "zrscAAHOWi9ova3DJsIEakTvOoW6EVtX",
  useAnalytics: true,
  logGreeting: false,
};
