export default {
  messages(state) {
    return state.messages;
  },
  loading(state) {
    return state.loading;
  },
  isDialog(state) {
    return state.isDialog;
  },
  chatInstanceId(state) {
    return state.chatInstanceId;
  },
};
