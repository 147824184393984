import Vue from "vue";
import Vuex from "vuex";
import config from "../bot-config/bot-config";
import messages from "./modules/messages";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    config,
    messages,
  },
});
