<template>
  <div>
    <chat-wrapper />
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {},
};
</script>

<style scoped>
.chat-box {
  height: 500px;
  width: 500px;
}
</style>
