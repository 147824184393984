<template>
  <div style="background-color: white">
    <v-divider class="mb-4"></v-divider>
    <div class="ml-4 mr-4">
      <v-form ref="form" @submit.prevent="submitMessage">
        <v-text-field
          v-model="message.text"
          class="rounded-pill"
          placeholder="Geben Sie eine Nachricht ein"
          append-outer-icon="mdi-map-marker"
          solo
          hide-details
        >
          <template slot="append-outer">
            <v-hover v-slot="{ hover }">
              <v-icon
                class="transition-ease-in-out"
                :color="hover ? 'primary' : ''"
                @click="submitMessage"
              >
                mdi-send
              </v-icon>
            </v-hover>
          </template>
        </v-text-field>
      </v-form>
      <!--      <v-btn class="mr-2" @click="addMessageBot">Als Bot senden</v-btn>
            <v-btn @click="addMessageUser">Als User senden</v-btn>-->

      <v-spacer />
      <div class="text-caption mt-2">
        Powered by&nbsp;<a href="https://institut-ida.de">Institut IDA</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    message: {
      text: "",
    },
    valid: false,
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      xAddMessageBot: "messages/addMessageBot",
      xAddMessageUser: "messages/addMessageUser",
    }),

    addMessageBot() {
      this.xAddMessageBot({ message: this.message });
    },
    addMessageUser() {
      this.xAddMessageUser({
        type: "question",
        message: this.message,
      });
    },
    submitMessage() {
      if (this.message.text) {
        if (this.message.text === "Bestatter finden") {
          this.xAddMessageUser({
            type: "dialog",
            message: this.message,
          });
        } else {
          this.xAddMessageUser({
            type: "question",
            message: this.message,
          });
        }

        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped>
.my-input.v-input .v-input__slot {
  border-radius: 100px;
}
</style>
