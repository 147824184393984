import axios from "axios";
import config from "../bot-config/bot-config";

export default ({ commit, dispatch }, message) => {
  commit("setLoading", true);
  axios
    .post(
      config.endpoint,
      {
        question: message.text,
        qnaId: message.qnaId,
        isTest: process.env.NODE_ENV === "development",
        scoreThreshold: config.confidenceScore,
      },
      {
        headers: {
          Authorization: config.endpointAuthKey,
        },
      }
    )
    .then((r) => {
      const botMessage = {};
      if (r.data.answers && r.data.answers[0]) {
        const result = r.data.answers[0];
        if (result.id === -1) {
          botMessage.text = config.noAnswerText;
          botMessage.actions = config.noAnswerSuggestions;
        } else {
          botMessage.text = result.answer;
          if (result.context && result.context.prompts) {
            try {
              botMessage.actions = [];
              result.context.prompts.forEach((item) => {
                botMessage.actions.push({
                  text: item.displayText.replace(/\\n/g, "\n"),
                  qnaId: item.qnaId,
                });
              });
            } catch (e) {
              console.log(e);
            }
          }
          if (result.metadata && result.metadata[0] && result.metadata[0].name === "dialog") {
            // store.commit("dialogs/setCurrentDialog", result.metadata[0].value);
            botMessage.dialog = result.metadata[0].value;
          }
        }
      } else {
        botMessage.text =
          "Leider kann ich Ihre Frage nicht beantworten. Bitte versuchen Sie es mit einer anderen Formulierung";
      }
      dispatch("addMessageBot", { message: { ...botMessage } }).then();
    })
    .catch((e) => {
      console.log(e);
      const botMessage = {};

      botMessage.text =
        "Leider kann ich Ihre Frage nicht beantworten. Bitte versuchen Sie es mit einer anderen Formulierung";

      dispatch("addMessageBot", { message: { ...botMessage } }).then();
    })
    .finally(() => {
      commit("setLoading", false);
    });
};
