<template>
  <v-app>
    <!--    <v-app-bar app color="primary" dark dense class="px-1">
      <div>{{ botTitle }} - {{ client }}</div>
    </v-app-bar>-->
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-footer class="pa-0" app>
      <div style="width: 100%; background-color: white">
        <chat-send-message />
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import config from "./bot-config/bot-config";

export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    botTitle() {
      return this.$store.getters["config/botTitle"];
    },
    client() {
      return this.$store.getters["config/client"];
    },
  },
  created() {
    if (config.primaryColor) {
      this.$vuetify.theme.themes.light.primary = config.primaryColor;
    }
    if (config.secondaryColor) {
      this.$vuetify.theme.themes.light.secondary = config.secondaryColor;
    }
  },
};
</script>

<style lang="scss">
.no-active:before {
  opacity: 0 !important;
}
.v-card__title {
  word-break: normal !important;
}
.theme--light.v-icon {
  color: #333333 !important;
}

.custom-link {
  color: inherit !important;
  text-decoration: none;
}

.custom-container {
  height: 100%;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.v-card__text {
  color: #333333 !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
}
</style>
