<template>
  <div>
    <div class="mt-4">
      <v-fade-transition>
        <div v-if="loading">
          Schreibt
          <span id="loadingAnimation">.</span>
        </div>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {
    loading() {
      return this.$store.getters["messages/loading"];
    },
  },
  watch: {},
  created() {
    if (this.loading) {
      window.setInterval(() => {
        const wait = document.getElementById("loadingAnimation");
        if (wait.innerHTML.length > 3) wait.innerHTML = "";
        else wait.innerHTML += ".";
      }, 250);
    }
  },
  methods: {},
};
</script>

<style scoped></style>
