import qnaMaker from "@/services/generate-answer";
import sendDataService from "@/services/analytics-tool";

export default {
  // eslint-disable-next-line no-unused-vars
  addMessageBot({ commit, getters }, { message }) {
    const { messages } = getters;
    const botMessage = message;
    botMessage.type = "bot";
    messages.push({ ...botMessage });
    setTimeout(function timeOutSendData() {
      sendDataService({ commit, getters }, { type: "bot", message });
    }, 1000);
    // commit('setMessages', messages.push(message));
  },
  // eslint-disable-next-line no-unused-vars
  addMessageUser({ commit, getters, dispatch }, { type, message }) {
    const { messages } = getters;
    const userMessage = message;
    userMessage.type = "user";
    messages.push({ ...userMessage });

    sendDataService({ commit, getters }, { type: "user", message });

    switch (type) {
      case "question":
        qnaMaker({ commit, dispatch }, message);
        break;
      case "dialog":
        dispatch("addMessageBot", {
          message: {
            text: "Wollen Sie die Bestattersuche starten?",
            actions: [{ text: "Jetzt Suche starten", startDialog: true }],
          },
        });
        break;
      default:
        break;
    }
  },
};
