<template>
  <div :id="'message' + id">
    <v-row :justify="message.type === 'bot' ? 'start' : 'end'">
      <v-avatar class="mt-4" radius="10px" outline size="40px">
        <img
          v-if="message.type === 'bot'"
          alt="Avatar"
          color="white"
          src="../../assets/avatar.png"
        />
        <v-icon v-else :color="message.color" v-text="message.icon"></v-icon>
      </v-avatar>
      <v-col
        cols="12"
        md="12"
        style="max-width: 70%"
        class="d-flex flex-column"
        :class="message.type === 'bot' ? 'align-start' : 'align-end'"
      >
        <v-card
          :color="message.type === 'bot' ? 'primary' : ''"
          :dark="message.type === 'bot'"
          width="max-content"
          margin-left="10px"
          elevation="2"
          outlined
          :class="message.type === 'bot' ? 'rounded-r-xl' : 'rounded-l-xl'"
        >
          <v-card-text :class="message.type === 'bot' ? 'white--text' : ''">
            <vue-markdown
              class="markdown-wrapper"
              :class="message.type === 'bot' ? 'markdown-invert-link' : ''"
            >
              {{ message.text }}
            </vue-markdown>

            <div v-if="message.dialog === 'funeralregulations'" class="mt-4">
              <v-select
                v-model="country"
                label="Wählen Sie Ihr Bundesland"
                :items="funeralRegulationsDialog.steps[0].items"
                hide-details
                @input="addFuneralRegulationsAnswer"
              />
            </div>
          </v-card-text>
        </v-card>

        <div v-if="message.actions && message.actions.length > 0">
          <template v-for="(action, index) in message.actions">
            <div :key="index" class="mt-2">
              <v-hover v-slot="{ hover }">
                <v-btn
                  outlined
                  rounded
                  color="primary"
                  medium
                  class="no-uppercase"
                  :elevation="hover ? 6 : 2"
                  @click.prevent="!action.startDialog ? addMessageUser(action) : (isDialog = true)"
                >
                  <vue-markdown class="markdown-wrapper no-uppercase" :breaks="false">
                    {{ action.text ? action.text : action }}
                  </vue-markdown>
                  <!-- {{ action.text ? action.text : action }} -->
                </v-btn>
                <!-- <v-card
                  class="transition-ease-in-out rounded-xl"
                  link                  
                  width="max-content"
                  :elevation="hover ? 6 : 2"
                  @click.prevent="!action.startDialog ? addMessageUser(action) : (isDialog = true)"
                >    
               
                  <v-card-text class="primary--text d-flex justify-center">
                   <v-sheet outlined color="error">
                    <vue-markdown class="markdown-wrapper" :breaks="false">
                      {{ action.text ? action.text : action }}
               </vue-markdown> 
                </v-sheet>
                    <v-icon v-if="action.startDialog" right color="primary">mdi-magnify</v-icon>
                  </v-card-text>
               
                </v-card>
             -->
              </v-hover>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import { mapActions } from "vuex";

export default {
  components: {
    VueMarkdown,
  },
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    country: "",
  }),
  computed: {
    messages() {
      return this.$store.getters["messages/messages"];
    },
  },
  watch: {},
  created() {
    if (this.id === this.messages.length - 1) {
      this.$nextTick(() => {
        const target = `#message${this.id}`;
        this.$vuetify.goTo(target);
      });
    }
  },
  methods: {
    ...mapActions({
      xAddMessageUser: "messages/addMessageUser",
      xAddMessageBot: "messages/addMessageBot",
    }),
    addMessageUser(action) {
      let message = {};
      if (action.text) {
        message = { ...action };
      } else {
        message.text = action;
      }
      this.xAddMessageUser({
        type: "question",
        message,
      });
    },
    addFuneralRegulationsAnswer() {
      const index = this.funeralRegulationsDialog.steps[0].items.indexOf(this.country);
      const res = this.funeralRegulationsDialog.steps[1].items[index];

      this.xAddMessageBot({ message: { text: res } });
    },
    currentDateTime() {
      const current = new Date();

      const time = `${current.getHours()}:${current.getMinutes()}`;

      return time;
    },
  },
};
</script>

<style>
.markdown-wrapper > p {
  margin-bottom: 12px;
  font-size: 14px;
}

.markdown-wrapper > p:last-child {
  margin-bottom: 0;
}
.chat-dialog {
  border-radius: 50%;
}
.markdown-invert-link > p > a {
  color: white;
  font-weight: bold;
}
.no-uppercase {
  text-transform: none;
}
</style>
