<template>
  <div>
    <div style="padding-bottom: 64px">
      <div class="gradient-divider"></div>
      <div class="pb-4 px-4">
        <chat-conversation :message="greeting" />
        <v-slide-y-transition group>
          <template v-for="(message, index) in messages">
            <chat-conversation :id="index" :key="index" :message="message" />
          </template>
        </v-slide-y-transition>
        <chat-loading />
      </div>
      <v-dialog
        v-model="isDialog"
        fullscreen
        @="
          dialog.getOffer = false;
          dialog.zip = '';
          dialog.email = '';
        "
      >
        <v-card>
          <v-toolbar dark color="secondary" tile dense class="mb-4 px-2">
            <v-toolbar-title>Finden Sie Ihren Bestatter</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-text-field v-model="dialog.zip" filled placeholder="Postleitzahl" />

              <v-slide-y-transition>
                <v-card v-if="dialog.zip === '90762'" class="mb-3">
                  <v-list-item v-for="(item, index) in dialog.items" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.description }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="mt-2">
                        <v-btn color="primary" x-small outlined @click="dialog.getOffer = true">
                          Persönliches Angebot einfordern
                        </v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-slide-y-transition>

              <v-slide-y-transition>
                <div v-if="dialog.zip === '90762' && dialog.getOffer">
                  <v-text-field v-model="dialog.email" placeholder="E-Mail-Adresse" filled />
                  <v-btn
                    color="primary"
                    dark
                    block
                    :disabled="!dialog.email"
                    @click="isDialog = false"
                  >
                    Absenden
                  </v-btn>
                </div>
              </v-slide-y-transition>
            </v-form>
          </v-card-text>
          <v-card-text>
            <v-btn color="secondary" dark block text @click="isDialog = false"> Abbrechen </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import config from "../../bot-config/bot-config";

export default {
  props: {},
  data: () => ({
    greeting: {},
    dialog: {
      zip: "",
      items: [{ title: "Stier Trauerhilfe", description: "Olaf Stier" }],
      getOffer: false,
      email: "",
    },
  }),
  computed: {
    messages() {
      return this.$store.getters["messages/messages"];
    },
    isDialog: {
      get() {
        return this.$store.getters["messages/isDialog"];
      },
      set(val) {
        return this.$store.commit("messages/setIsDialog", val);
      },
    },
  },
  watch: {
    isDialog() {
      this.$refs.form.reset();
    },
  },
  created() {
    this.greeting.text = config.greeting;
    this.greeting.actions = config.suggestions;
    this.greeting.type = "bot";
  },
  methods: {},
};
</script>
<style scoped>
.gradient-divider {
  height: 16px;
  position: sticky;
  z-index: 100;
  top: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
</style>
